<template>
  <div>
      <v-stepper v-model="pvSteps">
        <v-stepper-header>
          <v-stepper-step
            :complete="pvSteps > 1"
            step="1"
          >
            Objektsuche
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="pvSteps > 2"
            step="2"
          >
            Angaben zum Objekt
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="pvSteps > 3"
            step="3">
            Energiebezugsfläche
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step
            :complete="pvSteps > 4"
            step="4">
            Kontakte
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :complete="pvSteps > 5"
            step="5">
            Datenübermittlung
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            step="1">
            <validation-observer
              ref="observerStep1"
              v-slot="{ }"
            >
              <v-card
                class="step-content"
                elevation="0"
              >
                <v-col>
                  <v-row>
                    <v-col>
                      <p class="body-2 mb-0">Suchen Sie im Suchfeld nach der Adresse des Objekts.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p class="body-2 mb-0">Falls die Adresse nicht gefunden wird oder nicht bekannt ist, klicken Sie auf weiter und füllen Sie alle Angaben manuell aus.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-autocomplete
                        v-model="featureId"
                        :loading="loading"
                        :items="items"
                        :filter="autocompleteFilter"
                        :search-input.sync="search"
                        item-text="attrs.label"
                        item-value="attrs.featureId"
                        hide-no-data
                        hide-details
                        label="Adresse suchen"
                        clearable
                        solo
                        @change="findFeature"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="alert.addressInfo.state">
                    <v-col>
                      <v-alert
                        type="info"
                        v-model="alert.addressInfo.state"
                        border="left"
                        dense
                        class="my-0"
                        text>
                        {{ alert.addressInfo.message }}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <iframe
                        :src='mapUrl'
                        width='100%' height='450'
                        style='border:0'></iframe>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="nextStep()"
                >
                  Weiter
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-stepper-content>

          <v-stepper-content step="2">
            <validation-observer
              ref="observerStep1"
              v-slot="{ }"
            >
              <v-card
                class="step-content"
                elevation="0"

              >
                <v-col>
                  <v-row>
                    <v-col>
                      <h3>Angaben zum Gebäude</h3>
                    </v-col>
                  </v-row>
                  <v-form ref="formStep1"
                  >
                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            <h5>Bauvorhaben, Objekt</h5>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Municipal"
                              rules="required"
                            >
                              <v-select label="Gemeinde"
                                        v-model="form.municipal"
                                        :items="municipalList"
                                        item-text="name"
                                        :error-messages="errors"
                                        item-value="id"></v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ObjectStreet"
                              rules=""
                            >
                              <v-text-field label="Strasse"
                                            v-model="form.object_street"
                                            :error-messages="errors">
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ObjectStreetnumber"
                              rules=""
                            >
                              <v-text-field label="Hausnummer"
                                            v-model="form.object_streetnumber"
                                            :error-messages="errors">
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ObjectZip"
                              :rules="{ required: true, regex: /^[0-9]{4}$/ }"
                            >
                              <v-text-field label="PLZ"
                                            v-model="form.object_zip"
                                            :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ObjectCity"
                              rules=""
                            >
                              <v-text-field label="Ort"
                                            v-model="form.object_city"
                                            :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ObjectPlot"
                              rules="required_if_not:ObjectEgid"
                            >
                              <v-text-field label="Parzelle"
                                            v-model="form.object_plot"
                                            :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ObjectEgid"
                              rules="required_if_not:ObjectPlot"
                            >
                              <v-text-field
                                            label="Eidg. Gebäudeidentifiaktor (EGID)"
                                            v-model="form.egid"
                                            :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-card>
              <v-card-actions>
                <v-btn
                  @click="pvSteps = pvSteps - 1"
                  text
                  class="mr-4">
                  Zurück
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="nextStep()"
                >
                  Weiter
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-stepper-content>

          <v-stepper-content step="3">
            <validation-observer
              ref="observerStep2"
              v-slot="{ }"
            >
              <v-card
                class="step-content"
                elevation="0"
              >
                <v-col>
                  <v-form ref="formStep2">
                    <v-row>
                      <v-col>
                        <h3>Berechnung Ersatzabgabe</h3>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          name="GeneratorArea"
                          :rules="{required: true, integer: true}"
                        >
                          <v-text-field label="Energiebezugsfläche in m²"
                                        v-model="form.generator_area"
                                        :error-messages="errors">
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div v-if="form.generator_area && !isNaN(form.generator_area)" class="">
                          <p>
                            Für die eingegebene Energiebezugsfläche beträgt die <b>Ersatzabgabe:
                            <span v-if="form.generator_area < 50"> 0 Franken</span>
                            <span v-else-if="form.generator_area < 3000"> {{ numberWithDelimiter((form.generator_area / 100 * 2700).toFixed()) }} Franken</span>
                            <span v-else> 81'000 Franken</span>
                          </b>
                          </p>
                          <p v-if="form.generator_area < 50">
                            Leistung der Anlage = {{ numberWithDelimiter(form.generator_area) }} m&sup2; * 0.01 kWp/m&sup2; = {{ numberWithDelimiter(form.generator_area / 100) }} kWp<br>
                            <b>Ersatzabgabe = 0 Franken</b>
                          </p>
                          <p v-else-if="form.generator_area < 3000">
                            Leistung der Anlage = {{ numberWithDelimiter(form.generator_area) }} m&sup2; * 0.01 kWp/m&sup2; = {{ numberWithDelimiter(form.generator_area / 100) }} kWp<br>
                            <b>Ersatzabgabe </b>= {{ numberWithDelimiter(form.generator_area / 100) }} kWp * 2'700 Franken/kWp = <b>{{ numberWithDelimiter((form.generator_area / 100 * 2700).toFixed()) }} Franken</b>
                          </p>
                          <p v-else>
                            Leistung der Anlage = {{ numberWithDelimiter(form.generator_area) }} m&sup2; * 0.01 kWp/m&sup2; = {{ numberWithDelimiter(form.generator_area / 100) }} kWp<br>
                            <b>Ersatzabgabe = 81'000 Franken</b>
                          </p>
                        </div>
                        <p>
                          Die Ersatzabgabe berechnet sich wie folgt:
                        </p>
                        <ul>
                          <li>Energiebezugsfläche (abgekürzt EBF) kleiner als 3'000m&sup2;:</li>
                          <li class="my-2" style="list-style: none; text-indent: 2em">Ersatzabgabe (in Fr.) = EBF (in m&sup2;) * 0.01 kWp/m&sup2; * 2'700 Franken/kWp</li>
                        </ul>
                        <ul>
                          <li>Energiebezugsfläche 3'000 m² oder mehr (die Obergrenze gem. Art. 4c Bst. b beträgt 30 kW je Baute und wird mit
                            3'000m² erreicht):</li>
                          <li class="my-2" style="list-style: none; text-indent: 2em">Ersatzabgabe = 81'000 Franken</li>
                        </ul>
                        <p class="mt-3">
                          Diese Erklärung hält gestützt auf Art.5c Abs.1 EnG i.V.m. Art.4e der EnV, die Höhe der zu entrichtenden Ersatzabgabe
                          fest, welche die Hauseigentümerin oder Hauseigentümer zu entrichten haben.
                        </p>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-card>
              <v-card-actions>
                <v-btn
                  @click="pvSteps = pvSteps - 1"
                  text
                  class="mr-4">
                  Zurück
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="nextStep()"
                >
                  Weiter
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-stepper-content>
          <v-stepper-content step="4">
            <validation-observer
              ref="observerStep3"
              v-slot="{ }"
            >
              <v-card
                class="step-content"
                elevation="0"
              >
                <v-form ref="formStep3">
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            <h5>Bauherrschaft</h5>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="BuilderName"
                              rules="required"
                            >
                              <v-text-field label="Name, Vorname"
                                            v-model="form.builder_name"
                                            :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="BuilderStreet"
                              rules="required"
                            >
                              <v-text-field label="Strasse, Hausnummer"
                                            v-model="form.builder_street"
                                            :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="BuilderLocation"
                              rules="required"
                            >
                              <v-text-field label="PLZ, Ort"
                                            v-model="form.builder_location"
                                            :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            <h5>Kontaktperson</h5>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="ContactName"
                              rules="required"
                            >
                              <v-text-field label="Name, Vorname"
                                            v-model="form.contact_name"
                                            :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ContactPhone"
                              rules="required"
                            >
                              <v-text-field label="Telefon"
                                            v-model="form.contact_phone"
                                            :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="ContactEmail"
                              rules="required|email"
                            >
                              <v-text-field label="E-Mail"
                                            v-model="form.contact_email"
                                            :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <verification v-if="validContactEmail" @updateVerified="(verified) => emailIsVerified = verified" :email="form.contact_email"></verification>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-form>
              </v-card>
              <v-card-actions>
                <v-btn
                  @click="pvSteps = pvSteps - 1"
                  text
                  class="mr-4">
                  Zurück
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="!emailIsVerified"
                  @click="nextStep()"
                >
                  Weiter
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-stepper-content>
          <v-stepper-content step="5">
            <validation-observer
              ref="observerStep4"
              v-slot="{ invalid }"
            >
              <v-card
                class="step-content"
                elevation="0"
              >
                <v-form ref="formStep4">
                  <v-col>
                    <v-row>
                      <v-col>
                        <h5>Datenübermittlung bestätigen</h5>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p>Bitte überprüfen Sie Ihre Angaben. Sobald Sie Ihre Angaben bestätigt haben, können Sie die Daten übermitteln. Dabei wird ein Formular erzeugt, welches Sie unterschrieben als Beilage zum Baugesuch an die zuständigen Gemeinde einreichen müssen. </p>
                        <p class="pb-0 mb-0">Nach dem Sie die Daten übermittelt haben, können Sie Ihre Angaben nicht weiter bearbeiten!</p>
                      </v-col>
                    </v-row>
                    <v-row class="data-summary">
                      <v-col class="pt-0">
                        <h5 class="mt-0 mb-4">Ihre Eingaben:</h5>
                        <p class="my-2">Objekt:</p>
                        <ul>
                          <li><b>Adresse:</b> {{ form.object_street }} {{ form.object_streetnumber }}, {{ form.object_zip }} {{ form.object_city }} </li>
                          <li><b>Parzelle:</b> {{ form.object_plot }}</li>
                          <li><b>EGID:</b> {{ form.egid }}</li>
                          <li><b>Gemeinde:</b> {{ getMunicipalNameFromId(form.municipal) }}</li>
                          <li><b>Energiebezugsfläche:</b> {{ form.generator_area }} m&sup2;</li>
                        </ul>
                        <p class="my-2">PV-Ersatzabgabe:</p>
                        <ul>
                          <li><b>Ersatzabgabe:</b> Franken {{ getFee() }}</li>
                        </ul>
                        <p class="my-2">Bauherrschaft:</p>
                        <ul>
                          <li><b>Name, Vorname:</b> {{ form.builder_name }} </li>
                          <li><b>Adresse:</b> {{ form.builder_street }}, {{ form.builder_location }} </li>
                        </ul>
                        <p class="my-2">Kontakt:</p>
                        <ul>
                          <li><b>Name, Vorname:</b> {{ form.contact_name }} </li>
                          <li><b>Email:</b> {{ form.contact_email }} </li>
                          <li><b>Telefon:</b> {{ form.contact_phone }} </li>
                        </ul>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-checkbox label=""
                                    class="mr-0 mt-0"
                                    hide-details
                                    v-model="form.accept_transfer"
                        >
                          <template v-slot:label>
                            <div>
                              Ich bestätige die Richtigkeit und Vollständigkeit der Angaben und bin mit der Nutzung der Daten entsprechend der <a @click.capture="form.accept_transfer = !form.accept_transfer;dialog.privacy = true">Datenschutzerklärung</a> einverstanden.
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <v-alert
                          type="success"
                          v-model="alert.success.state">
                          {{ alert.success.message }}
                        </v-alert>
                        <v-alert
                          type="error"
                          dense
                          v-model="alert.error.state"
                        class="mb-0">
                          {{ alert.error.message }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-form>
              </v-card>

              <v-card-actions>
                <v-btn
                  @click="pvSteps = pvSteps - 1"
                  text
                  class="mr-4">
                  Zurück
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="transferData()"
                  :disabled="invalid"
                  :loading="dataTransfering"
                >
                  Daten übermitteln
                </v-btn>
              </v-card-actions>
            </validation-observer>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    <v-dialog
      v-model="dialog.areaWarning"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="headline">Energiebezugsfläche kleiner als 50m&sup2;</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <p>
                  Bauten mit einer Energiebezugsfläche von weniger als 50m² sind von der Eigenstromerzeugung nach Art.
                  5b Abs. 1 des Energiegesetzes befreit und müssen entsprechend auch keine Ersatzabgabe entrichten
                  (Siehe Art. 4d der Energieverordnung). Sie müssen das Formular nicht einreichen und können das
                  Formular verlassen.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog.areaWarning = false"
          >
            Angaben anpassen
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="resetForm()"
          >
            Formular verlassen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.successDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="headline">Übermittlung erfolgreich</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-alert type="success">Die Daten wurden erfolgreich übermittelt</v-alert>
                <p>Nachfolgend können Sie das PDF herunterladen, welches unterschrieben an Ihre Gemeinde eingesendet werden muss.
                  Sie erhalten den Link zum PDF zusätzlich auch als Email auf {{ form.contact_email }}</p>
                <v-btn
                  outlined
                  x-large
                  :href="pdf_url"
                  :download="filename"
                  >
                  PDF herunterladen<v-icon
                  right
                  dark
                >
                  mdi-download
                </v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editForm()"
          >
            Formular bearbeiten
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="resetForm()"
          >
            Abschliessen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.privacy"
      width="600px"
    >
      <privacy-dialog @closeDialog="dialog.privacy = false"></privacy-dialog>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
// eslint-disable-next-line camelcase
import { required, email, regex, required_if, integer } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'
import Verification from '../components/Verification'
import PrivacyDialog from '../components/PrivacyDialog.vue'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
})

extend('email', {
  ...email,
  message: 'Es muss eine Email Adresse eingegeben werden'
})

extend('regex', {
  ...regex,
  message: 'Die Eingabe entspricht nicht dem erwarteten Format'
})
extend('integer', {
  ...integer,
  message: 'Es werden nur Ganzzahlen akzeptiert.'
})

extend('required_if_not', {
  // eslint-disable-next-line camelcase
  ...required_if,
  validate: (value, args) => {
    const targetValue = args.target
    return Boolean(targetValue || value)
  },
  message: 'Parzelle oder EGID müssen ausgefüllt werden.'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Verification,
    PrivacyDialog
  },
  data () {
    return {
      validation: {
        step1: false
      },
      dialog: {
        successDialog: false,
        areaWarning: false,
        privacy: false
      },
      alert: {
        success: {
          state: false,
          message: 'Die Daten wurden erfolgreich übermittelt. Sie können das PDF herunterladen.'
        },
        error: {
          state: false,
          message: 'Es gab ein Problem bei der Datenübermittlung. Versuchen Sie es erneut oder melden Sie sich beim Support'
        },
        addressInfo: {
          state: false,
          message: 'Es wurde eine ungültige Adresse gewählt. Dieses Formular ist nur für Gemeinden im Kanton St. Gallen vorgesehen.'
        }
      },
      featureId: null,
      dataTransfering: false,
      filename: '',
      search: null,
      pvSteps: 1,
      energyFee: 0,
      municipalList: [],
      mapUrl: '//map.geo.admin.ch/embed.html?&zoom=1&lang=de&topic=ech&bgLayer=ch.swisstopo.swissimage',
      mapBaseUrl: '//map.geo.admin.ch/embed.html',
      mapConfig: {
        zoom: '12',
        lang: 'de',
        topic: 'ech',
        bgLayer: 'ch.swisstopo.swissimage'
      },
      formula: '$$x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}.$$',
      form: {
        egid: '',
        object_street: '',
        object_streetnumber: '',
        object_zip: '',
        object_city: '',
        object_plot: '',
        municipal: null,
        generator_area: '',
        builder_name: '',
        builder_street: '',
        builder_location: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        accept_transfer: false
      },
      loading: false,
      featureSelected: false,
      items: [],
      emailIsVerified: false,
      error: null,
      lazyLoad: true,
      pdf_url: ''
    }
  },
  created () {
    // this.setLazyLoad()
    this.getMunicipals()
  },
  methods: {
    setLazyLoad () {
      this.lazyLoad = true
      setTimeout(this.setLazyLoad, 1000)
    },
    getMunicipals () {
      axios
        .get('/municipalities')
        .then((response) => {
          if (response.data) {
            this.municipalList = response.data
          }
        }).catch(error => {
          console.log(error)
        })
    },
    nextStep () {
      switch (this.pvSteps) {
        case 1: {
          this.pvSteps += 1
          break
        }
        case 2: {
          this.$refs.observerStep1.validate()
            .then((valid) => {
              if (valid) {
                this.pvSteps += 1
              }
            })
          break
        }
        case 3: {
          this.$refs.observerStep2.validate()
            .then((valid) => {
              if (valid) {
                if (this.form.generator_area < 50) {
                  this.dialog.areaWarning = true
                } else {
                  this.pvSteps += 1
                }
              }
            })
          break
        }
        case 4: {
          this.$refs.observerStep3.validate()
            .then((valid) => {
              if (valid) {
                this.pvSteps += 1
              }
            })
          break
        }
        default: {
          break
        }
      }
    },
    async findFeature () {
      if (!this.featureId) return

      this.featureSelected = true
      await axios
        .get('https://api3.geo.admin.ch/rest/services/api/MapServer/ch.bfs.gebaeude_wohnungs_register/' + this.featureId + '?returnGeometry=true')
        .then((response) => {
          console.log(response.data)
          if (response.data && response.data.feature) {
            const feature = response.data.feature
            this.form.object_street = feature.attributes.strname[0]
            this.form.object_streetnumber = feature.attributes.deinr
            this.form.object_city = feature.attributes.dplzname
            this.form.object_zip = feature.attributes.dplz4
            this.form.object_plot = feature.attributes.lparz
            this.form.egid = feature.attributes.egid
            console.log(feature.geometry)
            this.findMunicipal(feature.geometry.x, feature.geometry.y)
            // x and y coords are exchanged in geometry feature
            this.showObject(feature.geometry.y, feature.geometry.x)

            let searchValue = feature.attributes.strname[0]

            if (feature.attributes.deinr) {
              searchValue = searchValue + ' ' + feature.attributes.deinr.split('.')[0]
            }

            setTimeout(() => {
              this.makeSearch(searchValue, this)
            }, 1000)
          }
        }).catch(error => {
          console.log(error)
        })
    },
    showObject (xcoord, ycoord) {
      const apiUrl = 'https://formularsystem.sg.ch/api/v1/'
      console.log(apiUrl.replace(/\//g, '%2F'))

      axios.get(`https://geodesy.geo.admin.ch/reframe/lv03towgs84?easting=${ycoord}&northing=${xcoord}&format=json`)
        .then((response) => {
          this.mapUrl = `
            ${this.mapBaseUrl}?X=${xcoord}&Y=${ycoord}&zoom=${this.mapConfig.zoom}&lang=${this.mapConfig.lang}&topic=${this.mapConfig.topic}&bgLayer=${this.mapConfig.bgLayer}&layers=KML%7C%7C${apiUrl.replace(/\//g, '%2F') + `kml%2F${response.data.easting}%2F${response.data.northing}`}
            `
          console.log(this.mapUrl)
        }).catch(() => {
          this.mapUrl = `
              ${this.mapBaseUrl}?X=${xcoord}&Y=${ycoord}
              &zoom=${this.mapConfig.zoom}&lang=${this.mapConfig.lang}&topic=${this.mapConfig.topic}&bgLayer=${this.mapConfig.bgLayer}
              `
        })
    },
    async findMunicipal (xcoord, ycoord) {
      await axios
        .get('https://api3.geo.admin.ch/rest/services/api/MapServer/identify?geometryType=esriGeometryPoint&returnGeometry=false&layers=all:ch.swisstopo.swissboundaries3d-gemeinde-flaeche.fill&geometry=' + xcoord + ',' + ycoord + '&imageDisplay=100,100,96&tolerance=0&lang=de;')
        .then((response) => {
          if (response.data && response.data.results) {
            let foundMunicipal = false
            for (const m of this.municipalList) {
              if (m.name.indexOf(response.data.results[0].attributes.gemname) >= 0) {
                this.form.municipal = m.id
                foundMunicipal = true
                this.alert.addressInfo.state = false
                break
              }
            }
            if (!foundMunicipal) {
              this.form.municipal = null
              this.alert.addressInfo.state = true
            }
          } else {
            this.form.municipal = null
          }
        }).catch(error => {
          console.log(error)
        })
    },
    makeSearch: async (value, self) => {
      // Handle empty value
      if (!value) {
        self.items = []
      }
      // Items have already been requested
      if (self.loading) {
        return
      }

      self.loading = true
      await axios
        .get('https://api3.geo.admin.ch/rest/services/api/SearchServer?lang=de&searchText=' + value + '&type=locations&origins=address')
        .then((response) => {
          if (response.data.results) {
            var locations = []
            response.data.results.forEach(function (location) {
              if (location.attrs.origin === 'address' || location.attrs.origin === 'parcel') {
                location.attrs.label = location.attrs.label.replace('<b>', '').replace('</b>', '')
                locations.push(location)
              }
            })
            self.items = locations
          }
        }).catch(error => {
          self.error = 'Unknown Error. Please check details and try again.' + error.msg
        })
        .finally(() => (self.loading = false))
    },
    transferData () {
      if (!this.form.accept_transfer) {
        this.alert.error.message = 'Bitte bestätigen Sie zuerst Ihre Angaben und die Nutzung der Daten gemäss Datenschutzerklärung.'
        this.alert.error.state = true
      } else {
        this.dataTransfering = true
        axios
          .post('/pv_applications', { application: this.form, token: sessionStorage.getItem('verification_token') }, {
            responseType: 'blob'
          })
          .then((response) => {
            if (response.status === 200 && response.data) {
              // this.alert.success.state = true
              this.filename = new Date().toISOString().substring(0, 10).replaceAll('-', '') + '-gesuch-pv-ersatzabgabe.pdf'
              this.pdf_url = URL.createObjectURL(response.data)
              this.alert.error.state = false
              this.dialog.successDialog = true
            } else {
              // this.alert.success.state = false
              this.alert.error.state = true
            }
          }).catch(error => {
            console.log('Unknown Error. Please check details and try again.' + error.msg)
            this.alert.success.state = false
            this.alert.error.message = 'Es gab ein Problem bei der Datenübermittlung. Versuchen Sie es erneut oder melden Sie sich beim Support'
            this.alert.error.state = true
          })
          .finally(() => {
            this.dataTransfering = false
          })
      }
    },
    resetForm () {
      window.location.reload()
    },
    editForm () {
      this.dialog.successDialog = false
      this.pvSteps = 2
    },
    getMunicipalNameFromId (id) {
      for (const m of this.municipalList) {
        if (m.id === id) return m.name
      }
      return ''
    },
    validEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    getFee () {
      let fee = 0
      if (this.form.generator_area >= 3000) {
        fee = 81000
      } else {
        fee = (this.form.generator_area / 100 * 2700).toFixed()
      }
      return this.numberWithDelimiter(fee)
    },
    numberWithDelimiter (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    },
    autocompleteFilter (item, queryText, itemText) {
      let matched = true
      queryText.split(' ').forEach((subText) => {
        if (itemText.toLocaleLowerCase().indexOf(subText.toLocaleLowerCase()) === -1) {
          matched = false
        }
      })

      return matched
    }
  },
  mounted () {
  },
  computed: {
    validContactEmail: {
      get () {
        return this.validEmail(this.form.contact_email)
      }
    }
  },
  watch: {
    search (value) {
      if (!value || value.length < 4) {
        return
      }

      this.makeSearch(value, this)
    }
  }
}

</script>
<style lang="scss" scoped>
.data-summary p, li {
  font-size: 14px;
}

ul li {
  list-style-type: none;
}
</style>
